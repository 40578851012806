<template>
  <div class="appWrapper">
    <div class="login-container">
      <form class="login-form" @submit.prevent="login">
        <label class="login-label" style="color: red;" v-if="errMsg !== ''">{{ errMsg }}</label>
        <label class="login-label" for="password">Password:</label>
        <div style="padding-bottom: 10px;">
          <input class="login-input" type="password" id="password" v-model="password" required>
        </div>
        <button class="login-button" type="submit">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import { logout } from "@/components/constants/functions";
import axios from "axios";

export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      errMsg: "",
    };
  },
  methods: {
    login() {
      this.errMsg = '';
      // Perform the login API request here
      if (this.password === '') {
        this.errMsg = "Please enter the password";
        return;  
      }
      const formData = new FormData();
      formData.append('password', this.password);
      axios
        .post("https://showroom.assetrepository.me/auth.php", formData)
        .then((response) => {
          console.log(response.data);
          if(response.data.message === 'success') {
            localStorage.setItem("token", "kjashdiuoy3w@asdkj");
            this.$router.push("/");
          } else {
            this.errMsg = "Invalid password";
            logout();
          }
        })
        .catch((error) => {
          this.errMsg = "Invalid password";
          logout();
          console.error("Login failed:", error);
        });
    },
  },
  mounted() {
    logout();
  },
};
</script>

<style>

.login-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-form {
  display: inline-block;
  text-align: left;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.login-label {
  display: block;
  margin-top: 1%;
  font-size: 5em;

}

.login-input {
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  font-size: 5em;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 5em;
  padding-top: 3%;
}

.login-button:hover {
  background-color: #0056b3;
}
</style>
