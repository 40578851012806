<template>
  <div>
    <div class="appWrapper">
      <div class="areaheader">
        <label>Our Nissan Range</label>
        <h1>Start your Nissan Journey</h1>
        <p class="txtMuted">
          Find your perfect Nissan and explore our wide and exciting range of vehicles.<br>
           Select one of the models below to start your journey.
        </p>
      </div>
    </div>

    <div style="padding:30px">
      <div class="areaheader" v-if="isLoading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div class="vehicleLineup"
      v-if="!isLoading"

      >
        <div
        class="item"
        v-for="(vehicle, i) in vehicleModelList"
          :key="i"

        >
              <div class="">
                <div class="vehicle-image" style="flex-basis: 59px;">
                  <a :href="'/vehicle/' + vehicle.name">
                    <img :src="vehicle.image"/>
                  </a>
                </div>

                <div class="vehicle-details">
                  <div class="vehicle-link" style="min-height: 107px;">
                    <div class="header-group no-chevron">
                      <h3 class="car-title">
                        <a class="title-link">
                          {{ vehicle.name }}
                        </a>
                      </h3>
                    </div>

                    <div class="starting-price">
                        <div class="startingprice">
                          <span>Starting price</span>

                        </div>
                        <div class="price">
                          {{ vehicle.price }}
                      </div>
                    </div>
                  </div>
                  <div class="cta-section" style="min-height: 116px;">
                    <a class="iconNissan primary-cta" :href="'/vehicle/' + vehicle.name" @click="addBtntagging('Explore', currentBaseUrl+'/vehicle/' + vehicle.name, vehicle.name, vehicle.code)">
                      <span>Explore</span>
                    </a>
                    <a class="iconNissan primary-cta" :href="vehicle.cta_1_url" @click="addBtntagging(vehicle.cta_1, vehicle.cta_1_url, vehicle.name, vehicle.code)">
                      <span>{{ vehicle.cta_1 }}</span>
                    </a>
                    <a class="iconNissan primary-cta" :href="vehicle.cta_2_url" @click="addBtntagging(vehicle.cta_2, vehicle.cta_2_url, vehicle.name, vehicle.code)">
                      <span>{{ vehicle.cta_2 }}</span>
                    </a>
                  </div>
                </div>
              </div>
        </div>
      </div>

      <!-- <vueper-slides v-if="!isLoading"
        fixed-height="900px"
        class="no-shadow"
        :visible-slides="4"
        slide-multiple
        :gap="3"
        :slide-ratio="1 / 4"
        :dragging-distance="100"
        :breakpoints="{ 800: { visibleSlides: 4, slideMultiple: 4} }"
      >
        <vueper-slide v-for="(vehicle, i) in vehicleModelList"
          :key="i"
        >
          <template #content>
            <div v-if="vehicle">
              <div class="">
                <div class="vehicle-image" style="flex-basis: 59px;">
                  <a :href="'/vehicle/' + vehicle.name">
                    <img :src="vehicle.image"/>
                  </a>
                </div>

                <div class="vehicle-details">
                  <div class="vehicle-link" style="min-height: 107px;">
                    <div class="header-group no-chevron">
                      <h3 class="car-title">
                        <a class="title-link">
                          {{ vehicle.name }}
                        </a>
                      </h3>
                    </div>

                    <div class="starting-price">
                        <div class="startingprice">
                          <span>Starting price</span>

                        </div>
                        <div class="price">
                          {{ vehicle.price }}
                      </div>
                    </div>
                  </div>
                  <div class="cta-section" style="min-height: 116px;">
                    <a class="iconNissan primary-cta" :href="'/vehicle/' + vehicle.name" @click="addBtntagging('Explore', currentBaseUrl+'/vehicle/' + vehicle.name, vehicle.name, vehicle.code)">
                      <span>Explore</span>
                    </a>
                    <a class="iconNissan primary-cta" :href="vehicle.cta_1_url" @click="addBtntagging(vehicle.cta_1, vehicle.cta_1_url, vehicle.name, vehicle.code)">
                      <span>{{ vehicle.cta_1 }}</span>
                    </a>
                    <a class="iconNissan primary-cta" :href="vehicle.cta_2_url" @click="addBtntagging(vehicle.cta_2, vehicle.cta_2_url, vehicle.name, vehicle.code)">
                      <span>{{ vehicle.cta_2 }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides> -->
    </div>
  </div>

</template>

<script>
import axios from "axios";
import { checkLogin, getGradeData } from '../components/constants/functions.js';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import adobeAnalytics from "@/plugin/analytics";

export default {
  name: "LandingView",
  props: {
    action: Boolean,
  },
  components: {
    VueperSlides, VueperSlide
  },

  data() {
    return {
      currentBaseUrl: window.location.origin,
      colorsIn: false,
      selectedvehicleModel: null,
      threesixtyStatus: true,
      selectedColor: null,
      vehicleFoundStatus: false,
      vehicleModelList: [],
      loaded:false,
      selectedVehicleModelName: null,
      selectedVehicleId:null,
      choosedVehicle: '',
      sidePanel: false,
      playOnce: false,
      colorName: null,
      exOptions: true,
      gradeIn: false,
      divLoaded: false,
      isLoadingFeature: true,
      isLoadingFeature2: true,
      isLoading: true,
      modelName: null,
      vehicle: {},
      vehicles: {},


      openHotSpotFormImgFeatureId: 0,

      toggleLeftFeatureBox: true,
      isActiveFeaturebox: true,
      toggleClicked: false,
      toggleRightFeatureBox: true,
      sm_toggleMenu: true,

      imageCount: 18,
      threeSixtyImagePath: null,
      threeSixtyFilename: "car_{index}.png",

      activeOptionId: 0,
      gradeWalkId: 0,
      activeGradeId: 0,
      activeFeatureId: 0,
      activeIntId: 1,
      isActiveMain: false,
      mainOptionRef: false,
      windowWidth: null,
      mobMode: false,
      isActiveColor: null,

      filteredResultArr: [],

      exteriorFeatures: true,
      interiorFeatures: false,

      spotWrapper: false,

      vehicleData: {
        grades: null,
        grade_data: {},
        model: null,
        category: null,
        categoryImageCount: null,
        interiorData: {},
      },
      dataChanged: null,
      setSpotCurrentImage: 0,
      widgetActive: false,
      hotSpotActiveId: "",
      colorsTray: true,
      imageColourList: {},

      selectedGrade: "",
      selectedGradeCategories: [],
      selectedGradeExteriorFeatures: [],

      itemsPerRow: 4,
      currentRow: 0,
      isLoggedIn: false,
    };
  },
  mounted: function () {
    this.$nextTick(function () {

      const login = checkLogin();
      if (login){
        this.isLoggedIn = login;
      } else {
        this.$router.push('/login');
      }


      if (this.selectedVehicleModelName) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

        this.isLoadingFeature2 = false;
        this.isLoadingFeature = false;
      }

      window.addEventListener("load", () => {
        this.windowWidth = window.innerWidth;
      });

      if (this.windowWidth < 991) {
        this.mobMode = true;
      }

      setTimeout(() => {
        if (this.imageCount > 20) this.divLoaded = true;
      }, 7000);
    });

    this.$el.addEventListener("click", this.onClick);
  },
  updated: function () { },
  computed: {
    rows() {
      const rowCount = Math.ceil(this.vehicleModelList.length / this.itemsPerRow);
      return Array.from({ length: rowCount }, (_, index) => index);
    },
    maxRow() {
      return this.rows.length - 1;
    },
  },
  created() {
    this.getVehicles();
  },
  methods: {
    addBtntagging(selectedCta, url, vehicle_name, vehicleid) {
      let event_happened = selectedCta;

      let event_126 = "";
      let events = '';

      let eVar14 = event_happened + "|" + url

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
    },
    toggleColorBox() {
      if (this.colorsIn == true) {
        this.colorsIn = false;
      } else{
        this.colorsIn = true;
      }
    },

    async getVehicles() {
      this.isLoading = true;
      try {
        const result = await axios.get(this.$apibaseurl + "api/middle-east-models");
        this.vehicleModelList = result.data.data;

        let event_happened = "Home";
        let event_126 = "";
        let events = '';
        let eVar14 = event_happened + "|" + this.currentBaseUrl
        adobeAnalytics(
        "R",
        "",
        "",
        "",
        "",
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        "",
        eVar14
      );
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },

    async getVehicleModelData(vehicleModelName) {
      const vehicleModelData = {};
      try {
        const result = await axios.get(this.$apibaseurl + "api/model/" + vehicleModelName);

        if (result.data.grades.length > 0) {
          const colorCode = 0;
          const gradeDataResponse = getGradeData(result.data.grades[0], 0, colorCode, 0, 0);
          for (const key in gradeDataResponse) {
            if (gradeDataResponse.hasOwnProperty(key)) {
              vehicleModelData[key] = gradeDataResponse[key];
            }
          }
          vehicleModelData.threesixtyStatus = true;
          vehicleModelData.vehicleFoundStatus = true;
        }
        return vehicleModelData;

      } catch (error) {
        console.log(error)
        return error;
      }
    },

    getRowVehicles(row) {
      const startIndex = row * this.itemsPerRow;
      const endIndex = startIndex + this.itemsPerRow;
      const rowVehicles = this.vehicleModelList.slice(startIndex, endIndex);

      let id = endIndex+1;
      while (rowVehicles.length < this.itemsPerRow) {
        rowVehicles.push({ id: id++ });
      }

      return this.vehicleModelList;
    },

    prevRow() {
      if (this.currentRow > 0) {
        this.currentRow--;
      }
    },

    nextRow() {
      if (this.currentRow < this.maxRow) {
        this.currentRow++;
      }
    },
  },

  watch: {},
};
</script>


<style>

.vueperslides__bullets--outside {
  margin-top: -50px !important;
}

.vueperslides__arrow svg {
  width: 5em;
}
.vueperslides__arrows{
  opacity: 0.5;
}
.vueperslides__arrow{
  transform: none;
  top:-250px;
}
.vehicle-details{
  padding-left: 50px;
}
.vehicle-link{
  margin-top: 25px;
}
.price{
  margin: 0;
  font: 70px/90px "Nissan Light","Nissan Regular",Verdana,Arial,sans-serif;
}

.starting-price{
  margin-bottom: 15px;
}

.content {
  -webkit-transition: all,1.2s,ease-out;
  -moz-transition: all,1.2s,ease-out;
  transition: all,1.2s,ease-out;
  visibility: visible;
  max-height: 500px;
  opacity: 1;
}

 img {
  border: 0;
  width: 720px;
  /* max-width: 100%; */
}

.primary-cta {
  padding: 40px 0;
  display: block;
  margin: 0;

  border: 0;
  text-decoration: none;
  text-align: left;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
}

.primary-cta:after {
  font-size: 2rem;
  content: "";
  color: #000;
  top: 50%;
  overflow: hidden;
  position: relative;
  padding-left: 10px;
  padding-top:30px;
  right: 0;
  margin-top: 0;
  display: inline-block;
}

.car-title, .car-title a {
    color: #000;
    text-decoration: none;
    font-size: 60px;
    padding-bottom: 25px;
}

.car-title {
    text-transform: uppercase;
    margin: 0 0 5px;
}

span {
  font-size: 45px;
}

.startingprice span{
  font-size: 40px;
}

.cta-section {
    border-top: 1px dashed #c1c1c1;
}

.image-row-relative {
  position: relative;
}

.navigation-buttons {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 100px;
  background-color: #000;
  color: white;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-buttons.left {
  left: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.navigation-buttons.right {
  right: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.navigation-buttons i {
  line-height: 80px;
  font-size: 24px;
}

.image-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.image-block {
  flex: 1;
  margin: 10px;
}

.custom-div {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
}

.areaheader {
  text-align: center;
  margin-bottom: 6.5%;
  margin-top: 6.5%;
}

.areaheader label {
  font-weight: bold;
  font-size: 3em;
}

.areaheader h1 {
  font-weight: lighter;
  font-size: 100px;
}

.disabled {
  background-color: whitesmoke;
  cursor: not-allowed;
}


.d-none {
  display: none;
}

.tooltip {
  position: absolute;
}

@media (max-width: 667px) {
  .closeIcon {
    font-size: 25px;
  }
}

@media (max-height: 768px) {
  .panIcon {
    height: 41%;
  }
}

.loading{
  width: 100%;
  display: flex;

}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  margin: auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
